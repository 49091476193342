import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { LoadingState } from '../../models/loading-state.model';

@Component({
  selector: 'mspot-load-btn',
  templateUrl: './load-btn.component.html',
  styleUrls: ['./load-btn.component.scss'],
  standalone: true,
  imports: [NgClass, TranslocoModule, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBtnComponent {
  @Input() set state(val: LoadingState | null | undefined) {
    switch (val) {
      case LoadingState.LOADING:
        this.loading = true;

        break;

      case LoadingState.SUCCESS:
        this.success = true;

        break;

      case LoadingState.CLEAR:
      default:
        this.loading = false;
        this.success = false;

        break;
    }
  }

  @Input() loading: boolean | null = false;

  @Input() showText = true;
  @Input() loadingText?: string;

  @Input() whiteSpinner = false;

  @Input() success = false;
  @Input() successText?: string;
}
