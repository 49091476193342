@if (!loading && !success) {
  <ng-content></ng-content>
} @else {
  @if (!success) {
    <div class="flex items-center">
      <div [ngClass]="{ 'white-spinner': whiteSpinner }">
        <svg
          [class.-ml-1]="showText"
          class="h-4 w-4 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      @if (showText) {
        <div class="ml-2 text-sm">
          {{ loadingText || ('common.processing' | transloco) }}
        </div>
      }
    </div>
  } @else {
    <div class="flex items-center">
      <div [ngStyle]="{ color: whiteSpinner ? 'white' : null }">
        <svg
          class="-ml-1 mr-2 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="text-sm">
        {{ successText || ('common.success' | transloco) }}
      </div>
    </div>
  }
}
